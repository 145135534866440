import React, { useState, useEffect } from "react"

export const useCookieConsent = () => {
  const [letOpen, setLetOpen] = useState(false)

  useEffect(() => {
    // Check local storage for a stored consent value
    const consentGiven = localStorage.getItem("cookieConsent")

    // Show popup only if no consent value is stored
    if (!consentGiven) {
      setLetOpen(true)
    }
  }, [])

  const cookieAccept = () => {
    setLetOpen(false)
    localStorage.setItem("cookieConsent", "accepted") // Store consent
  }

  const cookieDecline = () => {
    setLetOpen(false)
    localStorage.setItem("cookieConsent", "declined") // Store declined choice
  }

  return { letOpen, cookieAccept, cookieDecline }
}
