import React, { useState, useEffect } from "react"
import {
  Snackbar,
  SnackbarContent,
  Button,
  Box,
  Typography,
} from "@mui/material"
import { useLocation } from "@reach/router" // this helps tracking the location
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"
import { useCookieConsent } from "../hooks/useCookieConsent"

export const CookieBanner = ({ debug }) => {
  const { cookieAccept, cookieDecline, letOpen } = useCookieConsent()
  const location = useLocation()
  const [open, setOpen] = useState(letOpen)

  useEffect(() => {
    // Check local storage for a stored consent value
    const consentGiven = localStorage.getItem("cookieConsent")
    // Show popup only if no consent value is stored
    if (!consentGiven) {
      setOpen(true)
    }
  }, [])

  // CookieNotice

  const handleDecline = () => {
    console.log("Cookies Declined")
    setOpen(false)
    cookieDecline()
    if (typeof window !== "undefined" && window.gtag) {
      window.gtag("consent", "default", {
        ad_storage: "denied",
        analytics_storage: "denied",
        functionality_storage: "denied",
        personalization_storage: "denied",
        security_storage: "granted",
      })

      // Then update with the allowed cookies
      window.gtag("consent", "update", {
        analytics_storage: "granted",
        functionality_storage: "granted",
        security_storage: "granted",
      })

      console.log("Google Consent Mode updated: Cookies Declined")
    }

    // Inform the Gatsby GDPR plugin
    if (typeof window !== "undefined" && window.setGatsbyGdprCookies) {
      window.setGatsbyGdprCookies({
        googleAnalytics: false,
        googleTagManager: false,
        facebookPixel: false,
        hotjar: false,
      })
    }
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }

    setOpen(false)
  }

  const handleAccept = () => {
    setOpen(false)
    cookieAccept()
    if (typeof window !== "undefined" && window.gtag) {
      window.gtag("consent", "default", {
        ad_storage: "granted",
        analytics_storage: "granted",
        functionality_storage: "granted",
        personalization_storage: "granted",
        security_storage: "granted",
      })

      console.log("Google Consent Mode updated: Cookies Accepted")
    }

    // Enable tracking in gatsby-plugin-gdpr-cookies
    if (typeof window !== "undefined" && window.setGatsbyGdprCookies) {
      window.setGatsbyGdprCookies({
        googleAnalytics: true,
        googleTagManager: true,
        facebookPixel: true,
        hotjar: true,
      })
    }

    // Initialize tracking if using gatsby-plugin-gdpr-cookies
    if (typeof initializeAndTrack === "function") {
      initializeAndTrack(location)
    }
  }

  return (
    <>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={handleClose}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "tertiary.main",
            py: 3,
            px: 6,
            color: "white",
          },
        }}
      >
        <SnackbarContent
          sx={{
            backgroundColor: "secondary.main",
            color: "white",
          }}
          message={
            <Box>
              <Typography variant="body2">
                We use cookies on our website to make your experience better and
                to help us monitor and improve our customer service.
              </Typography>
              <Typography variant="body2">
                By continuing, we will assume that you are happy to receive all
                cookies. You can manage the use of cookies through your browser.
              </Typography>
            </Box>
          }
          action={
            <Box
              sx={{
                display: "flex",
                columnGap: 2,
              }}
            >
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                onClick={handleDecline}
              >
                Decline
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={handleAccept}
              >
                Accept
              </Button>
            </Box>
          }
        />
      </Snackbar>
    </>
  )
}
