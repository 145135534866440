import React, { useState, useRef } from "react"
import { Button as GatsbyButton } from "gatsby-theme-material-ui"
// import { animationHover } from "../utils/animationHover"
import {
  Link,
  Box,
  Menu,
  MenuItem,
  useTheme,
  Button,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

export const NavigationItem = props => {
  const { childNode, text, key, to, handleCloseNavMenu } = props

  const desktopContainerRef = useRef(null) // Reference to your custom container
  const mobileContainerRef = useRef(null) // Reference to your custom container

  //Desktop Menu
  const [anchorEl, setAnchorEl] = useState(null)
  const open = anchorEl
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
    setExpanded(false)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setExpanded(false)
  }

  // tablet Menu
  const [expanded, setExpanded] = useState("panel1")

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  const theme = useTheme()
  const tablet = useMediaQuery(theme.breakpoints.down("md"))

  return (
    <Box
      sx={{
        my: 0,
        mx: { xs: 0, md: 0 },
        px: { xs: 0, md: 0 },
        color: "highlight.main",
        display: "flex",
        textTransform: "unset",
      }}
    >
      {childNode && childNode?.length >= 1 && !tablet && (
        <Box ref={desktopContainerRef}>
          <Button
            id="demo-customized-button"
            aria-controls={open ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            variant="text"
            disableElevation
            onClick={handleClick}
            // onMouseEnter={handleHover} // Disabled for the moment to get it all styled
            //onMouseLeave={handleHoverOut}
            sx={{
              color: theme.palette.text.primary,
              textAlign: { xs: "left", md: "center" },
              justifyContent: { xs: "left", md: "center" },
              fontWeight: open === "true" ? 500 : 400,
              fontSize: {
                sm: theme.spacing(3),
                md: theme.spacing(3),
                lg: theme.spacing(4),
              },
              px: { xs: 1, sm: 1, md: 1, lg: 1 },
            }}
            endIcon={childNode?.length >= 1 && <ExpandMoreIcon />}
            size="large"
          >
            {text}
          </Button>

          <Menu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            keepMounted={true}
            container={desktopContainerRef.current} // Specify where to mount
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            slotProps={{
              paper: {
                elevation: 0,
              },
            }}
            sx={{ mt: theme.spacing(2) }}
          >
            {childNode &&
              childNode?.map((node, i) => {
                return (
                  <MenuItem
                    key={`submenu-${i}-${node?.text}`}
                    onClick={handleClose}
                    disableRipple
                    sx={{
                      px: theme.spacing(2),
                    }}
                  >
                    {node?.link?.internal ? (
                      <GatsbyButton
                        variant="text"
                        to={`/${node?.link?.internal?.slug?.current}`}
                        sx={{
                          color: theme.palette.text.primary,
                          px: 0,
                          justifyContent: "left",
                          fontWeight: 400,
                          px: { xs: 1, sm: 1, md: 1, lg: 1 },
                        }}
                        size="large"
                      >
                        {node?.text}
                      </GatsbyButton>
                    ) : node?.link?.external ? (
                      <Link
                        size="large"
                        href={node?.link?.external}
                        sx={{
                          color: theme.palette.text.primary,
                          px: 0,
                          justifyContent: "left",
                          fontWeight: 400,
                          px: { xs: 1, sm: 1, md: 1, lg: 1 },
                        }}
                      >
                        {node?.text}
                      </Link>
                    ) : null}
                  </MenuItem>
                )
              })}
          </Menu>
        </Box>
      )}

      {childNode && childNode?.length === 0 && !tablet && (
        <Box ref={mobileContainerRef}>
          <GatsbyButton
            id="demo-customized-button"
            aria-controls={open ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            variant="text"
            disableElevation
            //onClick={handleClick}
            // onMouseEnter={handleHover} // Disabled for the moment to get it all styled
            //onMouseLeave={handleHoverOut}
            sx={{
              color: theme.palette.text.primary,
              borderBottom: `1px solid ${open ? theme.palette.text.primary : `transparent`}`,
              textAlign: { xs: "left", md: "center" },
              justifyContent: { xs: "left", md: "center" },
              fontWeight: 400,
              px: { xs: 1, sm: 1, md: 1, lg: 1 },
            }}
            endIcon={childNode?.length >= 1 && <ExpandMoreIcon />}
            size="large"
            to={
              to?.link?.internal
                ? `/${to?.link?.internal?.slug?.current}`
                : to?.link?.external
            }
          >
            {text}
          </GatsbyButton>

          <Menu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            keepMounted={true}
            container={mobileContainerRef.current} // Specify where to mount
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            sx={{
              backgroundColor: theme.palette.background.main,
            }}
          >
            {childNode &&
              childNode?.map((node, i) => {
                return (
                  <MenuItem
                    key={`submenu-${i}-${node?.text}`}
                    onClick={handleClose}
                    disableRipple
                  >
                    {node?.link?.internal ? (
                      <GatsbyButton
                        size="large"
                        color="tertiary"
                        variant="text"
                        to={`/${node?.link?.internal?.slug?.current}`}
                        sx={{
                          // color: `${theme.palette.text.primary} !important`,
                          px: { xs: 1, sm: 1, md: 1, lg: 1 },
                        }}
                      >
                        {node?.text}
                      </GatsbyButton>
                    ) : node?.link.external ? (
                      <Link size="large" href={node?.link?.external}>
                        {node?.text}
                      </Link>
                    ) : null}
                  </MenuItem>
                )
              })}
          </Menu>
        </Box>
      )}

      {
        // tablet Menus
      }
      {childNode && childNode?.length === 0 && tablet && (
        <Button
          id="demo-customized-button"
          aria-controls={open ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="text"
          disableElevation
          onClick={handleClick}
          // onMouseEnter={handleHover} // Disabled for the moment to get it all styled
          //onMouseLeave={handleHoverOut}
          sx={{
            color: theme.palette.text.primary,
            borderBottom: `1px solid ${open ? theme.palette.text.primary : `transparent`}`,
            textAlign: { xs: "left", md: "center" },
            justifyContent: { xs: "left", md: "center" },
            px: theme.spacing(1),
            fontWeight: 400,
          }}
          endIcon={childNode?.length >= 1 && <ExpandMoreIcon />}
          size="large"
        >
          {text}
        </Button>
      )}

      {childNode && childNode?.length >= 1 && tablet && (
        <Accordion
          disableGutters
          elevation={0}
          expanded={expanded === `${key}`}
          onChange={handleChange(`${key}`)}
          sx={{
            backgroundColor: theme.palette.background.default,
            "& .MuiAccordionSummary-content": {
              my: theme.spacing(1),
            },
          }}
        >
          <AccordionSummary
            aria-controls={`${key}d-content`}
            id={`${key}d-content`}
            sx={{
              px: theme.spacing(0),
            }}
          >
            <Button
              id="demo-customized-button"
              variant="text"
              disableElevation
              sx={{
                color: theme.palette.text.primary,
                borderBottom: `1px solid ${expanded === `${key}` ? theme.palette.text.primary : `transparent`}`,
                textAlign: { xs: "left", md: "center" },
                justifyContent: { xs: "left", md: "center" },
                fontWeight: expanded === `${key}` ? "500" : "400",
                px: theme.spacing(1),
              }}
              endIcon={
                childNode?.length >= 1 && expanded === `${key}` ? (
                  <ExpandMoreIcon />
                ) : (
                  <ExpandMoreIcon sx={{ transform: "rotate(-90deg)" }} />
                )
              }
              size="large"
            >
              {text}
            </Button>
          </AccordionSummary>
          {childNode &&
            childNode?.map((node, i) => {
              return (
                <AccordionDetails
                  sx={{
                    py: theme.spacing(0),
                    px: theme.spacing(0),
                  }}
                >
                  {node?.link?.internal ? (
                    <GatsbyButton
                      size="large"
                      variant="text"
                      color="tertiary"
                      to={`/${node?.link?.internal?.slug?.current}`}
                      onClick={handleCloseNavMenu}
                      sx={{
                        justifyContent: "flex-start",
                        color: theme.palette.text.primary,
                        py: theme.spacing(1),
                        fontWeight: 400,
                      }}
                    >
                      {node?.text}
                    </GatsbyButton>
                  ) : node?.link?.external ? (
                    <Link
                      xs={{
                        fontWeight: 400,
                      }}
                      href={node?.link?.external}
                    >
                      {node?.text}
                    </Link>
                  ) : null}
                </AccordionDetails>
              )
            })}
        </Accordion>
      )}
    </Box>
  )
}
